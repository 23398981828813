// import { page } from '$app/stores';
// import { derived, writable, type Readable, type Writable } from 'svelte/store';

import { getContext } from 'svelte';
import type { Writable } from 'svelte/store';
import type { UserId } from './api';
import { serverFetch } from './api';

export interface UserEntity {
    id: UserId;
    name: string;
    settings: unknown;
}

export async function loadSession(): Promise<Session> {
    try {
        const response = await serverFetch('/api/session', {
            credentials: 'include'
        });

        if (!response.ok) {
            console.warn('/api/session returned non-200 status code');
            throw new Error('unable to load session from server!');
        }

        const data = (await response.json()) as { user?: UserEntity };

        console.log({ data });

        if (data?.user) {
            return { type: 'user', user: data.user };
        } else {
            return { type: 'guest', failed: false };
        }
    } catch (e) {
        console.warn('failed to fetch /api/session');
        throw e;
    }
}

export async function logout(session: Writable<Session>): Promise<void> {
    const response = await serverFetch('/api/session/logout', {
        credentials: 'include',
        method: 'POST'
    });

    if (response.status === 401 || response.ok) {
        // already logged out.
        session.set({ type: 'guest', failed: false });
        return;
    }
    console.warn('/api/session/logout returned non-200 status code');
    throw new Error('unable to logout!');
}

export type Session =
    | { type: 'user'; user: UserEntity }
    | { type: 'guest'; failed: boolean }
    | { type: 'loading' };

// export type Session = UserEntity | null;

export function setSession(session: Session) {
    getContext<Writable<Session>>('session').set(session);
}

export function getSession() {
    return getContext<Writable<Session>>('session');
}
